.ant-table-wrapper .ant-table {
  background-color: inherit;
}

.ant-table table {
  border-spacing: 0 4px;
}

.ant-table-body::-webkit-scrollbar {
  width: 8px;
  color: var(--color-bg-base);
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: var(--color-bg-base);
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}

.ant-table .ant-table-thead .ant-table-cell {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-text);
  padding: 0px 8px 0px 8px;
  border-width: 0;
  background-color: var(--color-bg-layout);
  vertical-align: bottom;
}

.ant-table .ant-table-body {
  overflow-y: auto !important;
}

.ant-table-column-sorters {
  background-color: var(--color-bg-layout);
}
.ant-table-column-sorters:hover {
  background-color: var(--color-bg-layout);
}

.ant-table-column-sorters:hover {
  background: var(--color-hover-bg);
}

.page-list-table-row {
  height: 48px;
  border-radius: 4px;
  background: var(--color-row-bg);
  color: var(--color-text);
}

.page-list-table-row-disabled {
  border-radius: 4px;
  background-color: var(
    --color-bg-container-disabled,
    rgba(255, 255, 255, 0.04)
  ) !important;
  color: var(--colorText, rgba(255, 255, 255, 0.85));
  cursor: default;
  padding: 0;
  margin: 0;
  height: 48px;
  line-height: 48px;
}

.ant-table .ant-table-tbody .page-list-table-row-disabled .ant-table-cell {
  padding: 8px;
  height: 48px;
  line-height: normal;
}
.ant-table
  .ant-table-tbody
  .page-list-table-row-disabled:hover
  .ant-table-cell {
  border-bottom: 1px solid var(--color-text);
  border-top: 1px solid var(--color-text);
  background-color: var(
    --color-bg-container-disabled,
    rgba(255, 255, 255, 0.04)
  );
}

.ant-table .ant-table-tbody .page-list-table-row .ant-table-cell {
  padding: 8px;
}
.ant-table .ant-table-tbody .page-list-table-row:hover .ant-table-cell {
  border-bottom: 1px solid var(--color-text);
  border-top: 1px solid var(--color-text);
  background: var(--color-hover-bg);
}
.ant-table .ant-table-tbody .page-list-table-row .ant-table-cell:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-table .ant-table-tbody .page-list-table-row .ant-table-cell:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-table
  .ant-table-tbody
  .page-list-table-row:hover
  .ant-table-cell:first-child {
  border-left: 1px solid var(--color-text);
}
.ant-table
  .ant-table-tbody
  .page-list-table-row:hover
  .ant-table-cell:last-child {
  border-right: 1px solid var(--color-text);
}

.clickable {
  cursor: pointer;
}

.ant-table .ant-table-tbody .page-list-table-row .ant-table-cell {
  padding: 8px;
  max-width: 660px;
  background-color: var(--color-row-bg);
}

.ant-table .ant-table-tbody .pink-background .ant-table-cell {
  background: var(--color-error-bg, #fff1f0);
  border: 1px solid var(--color-error-bg, #fff1f0);
}

.ant-table
  .ant-table-tbody
  .page-list-table-row-missing-reference
  .ant-table-cell {
  padding: 8px;
  max-width: 660px;
  background: var(--color-error-bg, hsl(4, 100%, 97%));
}

.ant-pagination .ant-pagination-item a {
  border-radius: 4px;
  background-color: var(--color-bg-layout);
  color: var(--color-text);
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--color-bg-base);
}

.ant-pagination-item-ellipsis {
  color: var(--color-text) !important;
}

.ant-pagination-item-link {
  color: var(--color-text) !important;
}
.ant-pagination a {
  color: var(--color-text) !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #7a40ea !important;
}

.ant-pagination-item-link {
  color: var(--color-text) !important;
}
.ant-pagination a {
  color: var(--color-text) !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #7a40ea !important;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  border-color: #7a40ea;
}
.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active a:hover {
  color: #7a40ea;
}

@media print {
  .ant-layout-content {
    height: fit-content !important;
    overflow: visible !important;
  }

  .ant-table .ant-table-body {
    overflow: visible !important;
  }
}
