/********/
/* tree */

.ant-tree {
  background-color: inherit;
}
.ant-tree .ant-tree-list .ant-tree-list-holder .ant-tree-node-content-wrapper {
  user-select: auto;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-title {
  cursor: auto;
}

.ant-tree-list .missing-reference-tree-node {
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  padding-bottom: 0;
  margin-bottom: 4px;
}
.ant-tree-list .ant-tree-treenode:hover {
  border: 1px solid #000;
}
.missing-reference-tree-node .ant-tree-node-content-wrapper {
  display: flex;
  flex-grow: 1;
  min-height: 48px;
  align-items: center;
  cursor: default;
}
.missing-reference-tree-node .ant-tree-node-content-wrapper:hover {
  background-color: initial;
}
.missing-reference-tree-node .ant-tree-node-content-wrapper .ant-tree-title {
  flex-grow: 1;
}
.missing-reference-tree-node
  .ant-tree-node-content-wrapper
  .ant-tree-title
  div {
  margin-bottom: 0;
}
.missing-reference-tree-node .ant-tree-switcher {
  display: flex;
  min-height: 100%;
  padding-left: 14px;
  width: 32px;
  align-items: center;
}

.included-missing-reference {
  background: var(--color-error-bg, #fff1f0);
  border: 1px solid var(--color-error-bg, #fff1f0);
}
.excluded-missing-reference {
  background: var(--color-bg-container-disabled, rgba(0, 0, 0, 0.04));
  border: 1px solid var(--color-error-bg, rgba(0, 0, 0, 0.04));
}

.ant-tree-list .document-tree-node {
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--color-row-bg, #fff);
  border: 1px solid var(--color-row-bg, #fff);
  color: var(--color-text);
  align-items: center;
  margin-bottom: 4px;
}

.ant-tree-list .document-tree-node:hover {
  border: 1px solid var(--color-text);
}
.document-tree-node .ant-tree-node-content-wrapper {
  flex-grow: 1;
  cursor: default;
}
.document-tree-node .ant-tree-node-content-wrapper:hover {
  background-color: initial;
}
.document-tree-node .ant-tree-switcher-noop {
  display: none;
}
.document-tree-node .ant-tree-indent-unit {
  margin-left: 10px;
}

/******************/
/* Directory tree */

.ant-tree-directory {
  background-color: inherit;
}

.ant-tree-directory .ant-tree-list .ant-tree-treenode {
  min-height: 48px;
  border: 1px solid var(--color-row-bg, #fff);
  background-color: var(--color-row-bg, #fff);
  align-items: center;
  border-radius: 4px;
  margin-bottom: 4px;
}
.ant-tree-directory .ant-tree-list .ant-tree-treenode:hover:before {
  background-color: inherit;
  border-radius: 4px;
}
.ant-tree-directory .ant-tree-list .ant-tree-treenode .ant-tree-switcher {
  align-self: center;
}
.ant-tree-directory
  .ant-tree-list
  .ant-tree-treenode
  .ant-tree-node-content-wrapper {
  padding-left: 0;
  cursor: default;
}
