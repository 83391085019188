.ant-message {
  font-size: 14px;
  max-width: 500px;
  word-break: break-word;
}

.ant-message .ant-message-notice-content {
  font-size: 14px;
  border: 1px solid;
}

.ant-message .ant-message-notice-success .ant-message-notice-content {
  background-color: #f6ffed;
  border-color: #b7eb8f;
}

.ant-message .ant-message-notice-error .ant-message-notice-content {
  background-color: #fff2f0;
  border-color: #ffccc7;
}

.ant-message .ant-message-notice-info .ant-message-notice-content {
  background-color: #e6f4ff;
  border-color: #91caff;
}

.ant-message .ant-message-notice-warning .ant-message-notice-content {
  background-color: #fffbe6;
  border-color: #ffe58f;
}
